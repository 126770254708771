<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template
            v-if="$permissionAbility(INCOME_TAX_POLICY_EDIT, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- format_starting_amount -->
          <template v-if="props?.column?.field === 'format_starting_amount'">
            <div>
              <span>
                {{ props?.row?.starting_amount }}
              </span>
            </div>
          </template>
          <!-- format_ending_amount -->
          <template v-if="props?.column?.field === 'format_ending_amount'">
            <div>
              <span>
                {{ props?.row?.ending_amount }}
              </span>
            </div>
          </template>

          <!-- salary_type -->
          <template v-if="props?.column?.field === 'format_rate'">
            <div>
              <span> {{ props?.row?.rate }}% </span>
            </div>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <template>
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template>
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-income-tax-slab-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Income Tax Slab'
          : 'Create Income Tax Slab'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="incomeTaxSlabValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Starting_amount -->
          <b-form-group
            label="Starting Amount"
            label-for="starting_amount"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="starting amount"
              vid="starting_amount"
              rules="required"
            >
              <b-form-input
                id="starting_amount"
                type="number"
                v-model="incomeTaxStartAmount"
                :state="errors.length > 0 ? false : null"
                placeholder="Starting Amount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ending_amount -->
          <b-form-group
            label="Ending Amount = Infinity Limit"
            label-for="ending_amount"
          >

              <b-form-checkbox
                :checked="switchValue"
                class="custom-control-primary"
                name="check-button"
                switch
                @input="onSwitchChange"
              />

          </b-form-group>

          <!-- ending_amount -->
          <b-form-group
            label="Ending Amount"
            label-for="ending_amount"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="ending amount"
              vid="ending_amount"
              rules="required"
            >
              <b-form-input
                id="ending_amount"
                type="number"
                v-model="incomeTaxEndAmount"
                :state="errors.length > 0 ? false : null"
                placeholder="Ending Amount"
                :disabled="isEndingAmountDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- rate -->
          <b-form-group
            label="Tax Rate(%)"
            label-for="rate"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="Tax Rate"
              vid="rate"
              rules="required|max:255"
            >
              <b-form-input
                id="rate"
                type="number"
                v-model="incomeTaxRateValue"
                :state="errors.length > 0 ? false : null"
                placeholder="Tax Rate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isIncomeTaxPolicyFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

    <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import { INCOME_TAX_POLICY_EDIT } from "@/helpers/permissionsConstant";
import { INCOME_TAX_INFINITE_AMOUNT } from "@/helpers/constant/incomeTaxInfiniteValueConstant"

export default {
  name: "IncomeTaxPolicyView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      INCOME_TAX_POLICY_EDIT,
      modelType: "",
      name: "",

      INCOME_TAX_INFINITE_AMOUNT,

      // Income tax policy
      incomeTaxStartAmount: "",
      incomeTaxRateValue: "",
      incomeTaxEndAmount: "",
      incomeTaxDetailsSlabId: "",

      switchValue: false,
      isEndingAmountDisabled: false,

      pageLength: 10,
      columns: [
        {
          label: "Starting Amount",
          field: "format_starting_amount",
          sortable: false,
        },
        {
          label: "Ending Amount",
          field: "format_ending_amount",
          sortable: false,
        },
        {
          label: "Rate",
          field: "format_rate",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      isIncomeTaxPolicyFormSubmitLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    onSwitchChange() {
      this.incomeTaxEndAmount = INCOME_TAX_INFINITE_AMOUNT;
      this.isEndingAmountDisabled = !this.isEndingAmountDisabled;
    },

    showModal() {
      this.$bvModal.show("modal-income-tax-slab-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-income-tax-slab-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.incomeTaxDetailsSlabId = "";
      this.incomeTaxStartAmount = "";
      this.incomeTaxRateValue = "";
      this.incomeTaxEndAmount = "";
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-income-tax-policy-details",
        params: { id },
      });
    },

    onShow(value) {
      this.modelType = "editModel";
      this.incomeTaxDetailsSlabId = value.id;
      this.incomeTaxStartAmount = value.starting_amount;
      this.incomeTaxEndAmount = value.ending_amount;
      this.incomeTaxRateValue = value.rate;

      this.showModal();
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/income-tax-details/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Income Tax Policy Slab Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getIncomeTaxPolicyDetailsSlabItems() {
      return await this.$api.get(
        `api/income-tax-policy/${this.$route.params.id}/slabs`
      );
    },

    async loadItems() {
      try {
        const [incomeTaxPolicyDetailsSlab] = await Promise.all([
          this.getIncomeTaxPolicyDetailsSlabItems(),
        ]);

        const data = incomeTaxPolicyDetailsSlab?.data?.data;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.incomeTaxSlabValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.incomeTaxSlabValidation.reset();
            if (this.modelType == "editModel") {
              this.isIncomeTaxPolicyFormSubmitLoading = true;
              await this.$api.put(
                `/api/income-tax-details/${this.incomeTaxDetailsSlabId}`,
                {
                  starting_amount: this.incomeTaxStartAmount,
                  ending_amount: this.incomeTaxEndAmount,
                  rate: this.incomeTaxRateValue,
                }
              );
              this.isIncomeTaxPolicyFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Income Tax Policy Slab Successfully Updated",
                },
              });
            } else {
              this.isIncomeTaxPolicyFormSubmitLoading = true;
              await this.$api.post("/api/income-tax-details", {
                starting_amount: this.incomeTaxStartAmount,
                ending_amount: this.incomeTaxEndAmount,
                rate: this.incomeTaxRateValue,
                income_tax_policy_id: this.$route.params.id,
              });
              this.isIncomeTaxPolicyFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Income Tax Policy Slab Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isIncomeTaxPolicyFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.incomeTaxSlabValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>

